/*Global CSS*/

body{
  margin: 0;
  font-family: "Roboto",sans-serif;
  -webkit-font-smoothing: "Roboto",sans-serif;
  -moz-osx-font-smoothing:"Roboto",sans-serif;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p{
  margin: 0;
}

:root{
  --primary:#009790;
  --heading:#00ffbf;
  --nav:#2e0022;
  --heading-color:#282938;
  --bg-shade:#d7f1fc;
  --instagram:#c00050;
  --darkblue:#1c1e53;
  --black:#000000;
  --white:#ffffff;
  --blue:#0073df;
  --pg: #e2f8f7e0;
  
}
.btn--insta{
  text-decoration: none;
}
.btn{
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius:5px;
  -moz-border-radius:5px;
  -ms-border-radius:5px;
  -o-border-radius:5px;
  transition: background-color 0.5s;
  -webkit-transition:background-color 0.5s;
  -moz-transition:background-color 0.5s;
  -ms-transition:background-color 0.5s;
  -o-transition:background-color 0.5s;
}
.btn-outline-primary{
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
.btn-outline-primary:hover{
  color: var(--white);
  background-color: var(--primary);
}
.btn-primary{
  color: var(--white);
  background: linear-gradient(45deg, #54771b, #1f374b);
  border: 1px solid var(--primary);
}
.btn-primary:hover{
  color: var(--primary);
  background-color: var(--white);
}
.btn-instagram{
  color: var(--white);
  background-color: var(--instagram);
  border: var(--instagram);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;
}
.btn-instagram:hover{
  color: var(--instagram);
  background-color: var(--white);
}
/*global section ends*/
/*main headings*/
/*heading 1*/
h1{
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}
/*heading 2*/
h2{
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}
/*heading 3*/
h3{
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}
 /*paragraphs text large medium small*/
 /*body 1*/
 .text-lg{
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
 }
/*body 2*/
.text-md{
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
 }

 /*body 3*/
 .text-sm{
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
 }
/*section titles*/
/*expertise & about me*/
.section--title{
  color: var(--heading-color);
  font-size: 21px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}
/*projects, blog & contact me*/
.sub--title{
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

}
/*section titles ends*/
/* navbar style start */
.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 105.333px;
  background: black;
  box-shadow:0px 5.333px 80px 0 rgba(0, 0, 0, 0.1) ;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.navbar--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 32.667px;
  text-decoration: none;

}
.navbar--items ul > li > a{
  text-decoration: none;
}
/* navbar content */
.navbar--content{
  color: var(--pg);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}
.navbar--active-content{
  color: var(--heading);
}
 /* hero section style */
 .hero--section{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 93.333px 85.333px 93.333px;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  background-image: url(../public/img/hero1_img\ .gif);
  
 }
 .hero--section-title--p{
  padding-left: 10%;
  font-weight: 600;
  color: rgb(90, 10, 54);
  font-size: 20px;
  
 }
 .hero--section--content-box{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
 }
 .hero--section--content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.333px;
  color: var(--pg);
 }
 .hero--section--content--box > button {
   margin-top: 5.333px;
 }
.hero--section--title{
 
  
  line-height: 90px;
  align-self: stretch;
  padding-top: 5%;
}
.hero--section--title--color{
  color: var(--heading);
  font-size: 78.667px;
}
.hero--section--description{
  color: var(--pg);
  font-size: 24px;
  line-height: 36px;
  font-weight: 400
  ;
}

.hero--section--img{
  display: flex;
  border-radius: 10px;
  margin-top: 50px;
  
}
.hero--section--img > img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  
}
/* hero section ends */
/* skills style */
.skills--section{
  display: flex;
  padding: 5.33px 9.33px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  background-color: var(--bg-shade);

}
.skills--section--heading{
  color: var(--heading-color);
  font-size: 20px;
  font-weight: 700;
  
}
.skills--section--container{
  display: grid;
  justify-content: center;
  align-items: flex-start;
  
  grid-template-columns: repeat(4,1fr);
}
.skills--section--card{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  border-radius: 10.6px;
  background: var(--bg-shade);
  min-height: 250px;
  padding-left: 2%;
  padding-right: 1%;
}
.button-skills{
  width: 70%;
}
.skills--section--card:hover{
  border-bottom:4px solid var(--primary) ;
}
.skills--section--card:hover .skills--description{
  border-bottom:4px solid var(--darkblue) ;
}
.skills--section--img{
  display: contents;
  padding: 3.3px;
  justify-content: center;
  align-items: center;
  gap: 3.3px;
  border-radius: 10.6px;
  background:#ffffff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
}
.skills--section--img >img{
  width: 100%;
  border-radius: 10.6px;
}
.skills--section--card--content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  align-self: stretch;

}
.skills--section--title{
  color: var(--darkblue);
  font-size: 14px;
  font-weight: 700;
  line-height: 0px;

}
.skills--section--description{
color: var(--heading-color); 
font-size: 10px;
font-style: italic;
font-weight: 400;
line-height: 22px;
justify-content: center;

}
/* skills style end */

/* about us */
.about--section{
  display: grid;
  padding: 0.3px 5.3px;
  align-items: center;
  background-image: url(../public/img/about_img\ .png);
  grid-template-columns: repeat(2, 1fr);
}
.about--section--content{
color: var(--black);
}
.about--section--heading{
  color: var(--bg-shade);
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  padding-left: 30%;
  text-decoration: underline;
}
.about--section-description {
  font-size: 16px;
  justify-content: flex-start;
  font-weight: 400;
  margin: 10px;
  font-family: cursive;
  color: #ffffff;

}
.about-section--heading{
  color: #3effff;
  margin-left: 50px;
  margin-bottom: 5px;
  text-decoration: underline;
}
.about--section--img > img{
  width: 100%;
  height: 55vh;
}
/* about ends */

/* projects portfolio */
.portfolio--section{
  display: flex;
  padding: 12px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding-left: 5%;
  padding-right: 5%;

}
.portfolio--container-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.portfolio--container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.3px;
}
.portfolio--section--container{
  display: flex;
 align-items: center;
  justify-content: center;
  gap: 12.6px;
  width: 100%;
 

}
.portfolio--section--img{
  border-radius: 8px;
  width: 100%;
   
}
.section--heading{
  font-size: 30px;
}
.portfolio--section--img > img{
  width: 100%;  
  border-radius: 10.6px;
}
.portfolio--section--card{
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 gap: 2px;
 flex: 1 0 0;
 background: #ffffff;  
 box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
 cursor: pointer;
 border-top-left-radius: 12px;
 border-top-right-radius: 12px;
}
.portfolio--section--card--content{
 display: flex;
 margin: 3px;
 flex-direction: column;
 align-items: flex-start;
 gap: 32px;
 align-self: stretch;
   
}
.portfolio--section--title{
  color: var(--instagram);
}
.portfolio--link{
  text-decoration: none;
  display: flex;
  gap: 16px;
  border-bottom: 1.33px solid var(--primary) ;
  padding-bottom: 10.6px;
  font-weight: 600;
}
.link--view{
 text-decoration: none;
}
.portfolio--section--card:hover path{
  stroke: #f703ff;
}
.abt--section--img > img{
  width: 100%;
  height: 60vh;
  border-radius: 10.6px;
}

/* contactus */
.contact--section{
  background-color: #ffffff;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 0%;
  height: 70vh;
  margin: 0;
}
.container {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgb(0, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
}

.contact-details {
  width: 55%;
  margin: 10px;
  
}

.contact-details h2 {
  color: #01422f;
  margin-bottom: 10px;
  font-size: 20px;
}

.contact-details p {
  margin-bottom: 8px;
  color: #002e53;
  padding-left: 2%;
}

.contact-details strong {
  font-weight: bold;
  color: #39193d;
}

.office-hours,
.location {
  margin-bottom: 20px;
}

.map-container {
  width: 50%;
  height: 60vh;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 8px;
}
/* contact ends */

/* Footer Start */
/* Footer Starts */
.footer--container {
  display: flex;
  padding: 0.667px 50.333px;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(../public/img/footer_img.png);
  align-items: center;
  align-self: stretch;
}
.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
  color: var(--white);
}
.footer--items ul > li > a {
  text-decoration: none;
  color: var(--heading);
  cursor: pointer;
}
.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
  content: var(--instagram);
}
.footer--social--icon > ul > li > a{
  color: var(--white);
}
.divider {
  margin: 6px 0 2.67px;
  height: 0.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}
.footer--content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: var(--white);
  font-size: 10.667px;
  font-weight: 400;
  line-height: 28px;
}
.videocontainer h1{
 color: #bbff00;
 font-size: 38px;
}
.videocontainer p{
  color: #a5fcf7;
  font-weight: 600;
  font-size: 25px;
}
.videocontainer{
  max-width: 800px;
  max-height: 600px;
  padding-left: 2%;
  padding-right: 2%;
}
.video{
  width: 100%;
  height: 100%;
}
.getinvolved--section{
    display: grid;
    padding: 5.3px 65.3px;
    align-items: center;
    gap: 10px;
    background-image: url(../public/img/about_img\ .png);
    grid-template-columns: repeat(2, 1fr);
  }


/* Responsive Screens */
@media only screen and (max-width: 1800px) {
  .hero--section--title {
    font-size: 68px;
    line-height: 70px;
  }
  .skills--section--title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 1600px) {
  .skills--section--heading {
    font-size: 54px;
    line-height: 70px;
  }
  .skills--section--container {
    gap: 16px;
  }
  .skills--section--card {
    gap: 28px;
  }
  .skills--section--card--content {
    gap: 20px;
  }
  .skills--section--title {
    font-size: 23px;
    line-height: 30px;
  }
  .skills--section--description {
    font-size: 17.333px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 720px) {
 .videocontainer{
  width: 100%;
  padding-top: 5%;
  padding-left: 0%;
  
  
 }
 .about--section--img > img{

  width: 100%;
  height: 50vh;
  margin-top: 5px 
}
 
 .navbar{
  padding-left: 3%;
  padding-right: 3%;
  width: 95%;
  background: #000000;
 }
  
  
  .btn-outline-primary {
    display: none;
  }
  .hero--section {
    display:list-item;
    padding-bottom: 0px;
    padding-top: 20%;
    width: 100%;
    padding-left: 0%;
     padding-right: 0%;
     background-image: url(../public/img/hback.png);
  }
  .hero--section--title,
  .hero--section-description,
  .footer--content {
    text-align: center;
    font-size: medium;
  }
  .hero--section--title{
    font-size: 30px;
    font-weight: 800;
  }
  .hero--section--title--color{
    color: hsl(330, 100%, 50%);
    font-size: 30px;
    margin-top: 2px;
    margin-bottom: 8px;
  }
  .hero--section-title--p{
    font-size: 14px;
    margin-top: 6px;
    color: #c0b2a2d0;
  
  }
  
  
  .hero--section--content--box{
    margin: 10px;
  }
  .btn.btn-primary {
    width: 80%;
    align-content: center;
    margin-right: 25px;
    margin-left: 30PX;
    color: black;
    font-weight: 600;
    background: linear-gradient(45deg, #54771b, #1f374b);
}
  .portfolio--section {
    display: flex;
    padding: 12px 85px;
    flex-direction: column;
    align-items: flex-start;
    
    padding-left: 2%;
    padding-right: 2%;
  }

  .hero--section-description{
    padding-bottom: 2%;
    font-size: 14px;
    font-style: italic;
  }
  .skills--section--container{
  
   display: flex;
   flex-direction: column;
  }
  
  .about--section,
  .portfolio--section--container,
  .portfolio--container-box,
  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }

  .hero--section--content,
  .hero--section--content--box {
    align-items: center;
  }
  .hero--section--img {
    display: flex;
    border-radius: 10px;
    margin-top: 0px }
  .skills--section--img{
    width: 100%;
  }
  .portfolio--container-box {
    gap: 5px;
    margin: 1px;
    
  }
  
  .portfolio--container{
    margin: 1px;
  }
  
  .testimonial--section,
  .contact--section,
  .footer--container {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 2%;
    padding-right: 2%;
  }
  .about--section {
    gap: 20px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2%;
    padding-right: 2%;
  }
  .getinvolved--section {
    display: list-item;
    gap: 0px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2%;
    padding-right: 2%;
  }
  
  
    .about--section-description {
      font-size: 16px;
      justify-content: flex-start;
      font-weight: 400;
      margin: 10px;
      font-family: cursive;;
  }
  .about-section--heading{
    color: #00ffff;
    text-decoration: underline;
    margin-left: 50px;
    margin-bottom: 2px;
  }
  .testimonial--section--card {
    padding: 25px;
  }
  .hero--section--title,
  .skills--section--heading,
  .sections--heading {
    font-size: 35px;
    line-height: 40px;
  }
  .hero--section--content--box {
    gap: 10px;
  }
  .container {
    display: flex;
    flex-direction: column;
    height: 83vh;
    
    
  }
  
  .contact--section{
    display:list-item;
   margin: 10px;
   padding-top: 1px;
   
    
    
  }
  .contact-details {
    width: 100%;
  }
  .map-container {
    display: flex;
   flex-direction: column;
   width: 100%;
   height: 50vh;
   
  }
  
  .map-container iframe {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 8px;
  }
  
  .footer--container {
    margin: 0%;
    background: #431b5a;

  }
  .footer--container {
    gap: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 2%;
    padding-right: 2%;
}
.footer--items{
  display: block;
}
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
    
  }
  .divider {
    margin: 20px;
  }
}

/* Hamburger menu  */

.nav__hamburger {
  display: none;
  width: 2.875rem;
  height: 2.313rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 25px;
  right: 25px;
  
}


.nav__hamburger__line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #f094f8;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}

@media screen and (max-width: 700px) {
  .nav__hamburger {
    display: flex;
    z-index: 200;
  }
  .navbar--items {
    display: flex;
  }
  .navbar--items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#000000, #200d2b);
    top: -20rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
  }
  .navbar--items ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 0.6rem;
    z-index: -100;
  }
  .navbar--items ul li {
    text-align: center;
  }
  .navbar--items ul li a {
    padding: 0.5rem;
  }
  .navbar--items {
    z-index: -1000;
  }
  .navbar--items.active {
    top: 30px;
  }
  .nav__hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav__hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav__hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }
}

/* Responsive Screens Ends*/
